export default function About(){

    return (

<div>
    <h1>
        Aboute Me
    </h1>
</div>
    )

}